import React, { memo } from 'react'
import Layout from '../layouts/Layout'

const Asco = props => {
  return (
    <Layout canonicalURL='' title='' pageTitle='' description=''>

    </Layout>
  )
}

export default memo(Asco)